/* eslint-disable */
import app from '../dva'
import {
  dynamic as dvaDynamic
} from 'dva'

if (!global.registered) {

  app.model(require('/builds/MijoSqMn/0/mcfe/misrc/src/models/global.js').default)

  app.model(require('/builds/MijoSqMn/0/mcfe/misrc/src/models/menu.js').default)

  app.model(require('/builds/MijoSqMn/0/mcfe/misrc/src/models/setting.js').default)

}
global.registered = true

export const pathMap = {
  'home': '/',
  'notFound': '/notFound',
}

const routes = [{
  path: '/',
  name: 'home',
  icon: '',
  component: dvaDynamic({
    app,
    component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/layouts/BasicLayout'),
    LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
  }),
  hideInMenu: false,
  hideChildrenInMenu: false,
  hide: false,
  exact: false,
  target: '',
  title: '首页',
  routes: [{
      path: '/',
      title: 'index',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Home/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Home/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/Home/models'
      ],
      exact: true
    },
    {
      path: '/notFound',
      name: 'notFound',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/NotFound/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
      }),
      exact: true
    },
    {
      title: 'blogDetail',
      path: '/blogdetail/:id',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Blog/Detail'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Blog/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/Blog/models'
      ],
      exact: true
    },
    {
      title: 'blog',
      path: '/blog/:tab?/:category?',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Blog/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Blog/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/Blog/models'
      ],
      exact: true
    },
    {
      title: 'noticeDetail',
      path: '/notice/detail/:id',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Notice/Detail'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Notice/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/Notice/models'
      ],
      exact: true
    },
    {
      title: 'notice',
      path: '/notice/:tabKey?',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Notice/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Notice/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/Notice/models'
      ],
      exact: true
    },
    {
      title: 'hero',
      path: '/hero',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Hero/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
      }),
      exact: true
    },
    {
      title: 'heroDetail',
      path: '/hero/detail/:id',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Hero/Detail'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
      }),
      exact: true
    },
    {
      title: 'mall',
      path: '/mall',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Mall/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Mall/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/Mall/models'
      ],
      exact: true
    },
    {
      title: 'aboutUs',
      path: '/aboutUs',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/AboutUs/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/AboutUs/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/AboutUs/models'
      ],
      exact: true
    },
    {
      title: 'bugDetail',
      path: '/bugDetail/:id',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/BugDetail/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/BugDetail/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/BugDetail/models'
      ],
      exact: true
    },
    {
      title: 'IoT',
      path: '/IoT',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/models'
      ],
      exact: true
    },
    {
      title: 'IoT',
      path: '/IoTList',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/List'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/models'
      ],
      exact: true
    },
    {
      title: 'IoTDetail',
      path: '/IoT/:id',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/Detail/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/models'
      ],
      exact: true
    },
    {
      title: 'applyIoT',
      path: '/applyIoT',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/Apply/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/IoT/models'
      ],
      exact: true
    },
    {
      title: 'personalCenter',
      path: '/personalCenter',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/Index'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models'
      ],
      routes: []
    },
    {
      title: '个人中心',
      icon: 'icongerenzhongxin',
      path: '/personalCenter/center',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/Center/Center'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models'
      ],
      exact: true
    },
    {
      title: '我的消息',
      icon: 'iconwodexiaoxi',
      path: '/personalCenter/message',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/Message/Message'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models'
      ],
      exact: true
    },
    {
      title: '漏洞列表',
      icon: 'iconloudongliebiao',
      path: '/personalCenter/bug',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/Bug/Bug'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models'
      ],
      exact: true
    },
    {
      title: '礼物兑换',
      icon: 'iconliwuduihuan',
      path: '/personalCenter/gift',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/Gift/Gift'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models'
      ],
      exact: true
    },
    {
      title: '我的团队',
      icon: 'iconwodetuandui',
      path: '/personalCenter/team',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/Team/Team'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models'
      ],
      exact: true
    },
    {
      title: '我的信息',
      icon: 'iconwodexinxi',
      path: '/personalCenter/info',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/Info/Info'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/PersonalCenter/models'
      ],
      exact: true
    },
    {
      title: '提交安全漏洞',
      icon: 'security',
      path: '/security',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Security'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Security/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/Security/models'
      ],
      hideInMenu: true,
      exact: true
    },
    {
      title: '提交隐私漏洞',
      icon: 'privacy',
      path: '/privacy',
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Privacy'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
        models: () => [import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Privacy/models/index.js').then((m) => {
          return m.default
        }), ]
      }),
      models: [
        '/builds/MijoSqMn/0/mcfe/misrc/src/pages/Privacy/models'
      ],
      hideInMenu: true,
      exact: true
    },
    {
      path: '/policy/privacy',
      hideInMenu: true,
      component: dvaDynamic({
        app,
        component: () => import('/builds/MijoSqMn/0/mcfe/misrc/src/pages/Policy/Privacy'),
        LoadingComponent: require('/builds/MijoSqMn/0/mcfe/misrc/src/components/PageLoading').default,
      }),
      exact: true
    },
    {
      path: '/*',
      exact: true,
      routes: [{
        path: '/*',
        redirect: '/notFound',
        hideInMenu: true,
        _dealedRedirect: true,
        exact: true
      }]
    }
  ]
}]
routes.LoadingComponent = '@/components/PageLoading'

export default routes